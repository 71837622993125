import React from "react";
import LogoYellow from "../../assets/images/logo-yellow.png";

import "./Menu.css";

function Menu({ onOpen }) {
  return (
    <header id="tt-header" className="tt-header-fixed">
      <div className="tt-header-inner">
        <div className="tt-header-col">
          <div className="tt-logo">
            <a href="https://opensea.io/collection/apostles-genesis" rel="noreferrer" target="_blank">
              <img
                src={LogoYellow}
                className="tt-logo-light magnetic-item"
                alt="Logo"
              />
              <img
                src={LogoYellow}
                className="tt-logo-dark magnetic-item"
                alt="Logo"
              />
            </a>
          </div>
        </div>
        <div className="tt-header-col">
          <div id="tt-ol-menu-toggle-btn-wrap">
            <div className="tt-ol-menu-toggle-btn-text">
              <span className="text-close gradient-text f-size-34 font-sweetrevenge">
                Close
              </span>
            </div>
            <div className="tt-ol-menu-toggle-btn-holder text-menu">
              <div
                onClick={onOpen}
                className="tt-ol-menu-toggle-btn magnetic-item"
              >
                <span></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Menu;
