import { createContext, useState } from "react";
import WalletConnectProvider from "@walletconnect/web3-provider";
import QRCodeModal from "@walletconnect/qrcode-modal";

import GenesisApostle from "../../contracts/GenesisApostle.json";
import PillContract from "../../contracts/BYOPillLive.json";
import KeyContract from "../../contracts/BYOKey.json";

import Web3 from "web3";

const initialState = {
  account: null,
  web3: null,
};

const Web3Context = createContext();

// Wallet Connect
const provider = new WalletConnectProvider({
  infuraId: "d8fa5fe7db584c04b89cc07622381c6e",
  qrcode: false,
  pollingInterval: 10000,
});

const Web3ContextProvider = ({ children }) => {
  const [account, setAccount] = useState(initialState.account);
  const [contract, setContract] = useState(null);
  const [pillContract, setPillContract] = useState(null);
  const [keyContract, setKeyContract] = useState(null);
  const [web3, setWeb3] = useState(initialState.web3);
  const [initialized, setInitialized] = useState(false);

  const initializeWeb3 = async () => {
    var web3 = null;
    if (window.ethereum) {
      // Metamask
      web3 = new Web3(window.ethereum);
      setWeb3(web3);
    } else {
      // No metamask, fallback to wallet connect
      try {
        await provider.enable();
        QRCodeModal.close();
        provider.on("disconnect", (accounts) => {
          setAccount(null);
        });
        web3 = new Web3(provider);
        setWeb3(web3);
      } catch (e) {
        console.log(e);
      }
    }
    // Get accounts
    await getAccounts(web3);

    // Get contract
    var nftContract = null;
    var pillContract = null;
    var keyContract = null;
    try {
      nftContract = new web3.eth.Contract(
        GenesisApostle.abi,
        "0xE70Da20A2B10d60Ca620a4E494Fe2B37C9499E97"
      );

      pillContract = new web3.eth.Contract(
        PillContract.output.abi,
        "0xbD275ce24f32d6cE4e9d9519C55ABe9Bc0ed7fCf"
      );

      keyContract = new web3.eth.Contract(
        KeyContract.abi,
        "0x07f7C1fB71a4b3D50f6146d13b53F115AFB83236"
      )
    } catch (e) {
      alert(
        "Failed to setup Ethereum connection, please check your wallet network."
      );
      console.log(e);
    }

    setContract(nftContract);
    setPillContract(pillContract);
    setKeyContract(keyContract);

    setInitialized(true);
  };

  const getAccounts = async (web3) => {
    const accounts = await web3.eth.getAccounts();
    if (accounts.length > 0) {
      setAccount(accounts[0]);
    }
  };

  const connectMetamask = async () => {
    if (window.ethereum) {
      // Metamask direct
      window.ethereum
        .request({ method: "eth_requestAccounts" })
        .then((accounts) => {
          if (accounts.length === 0) {
            console.log("Please connect to MetaMask.");
          } else if (accounts[0] !== account) {
            setAccount(accounts[0]);
          }
        });
    } else {
      // Wallet connect
      try {
        QRCodeModal.open(provider.connector.uri, () => {
          QRCodeModal.close();
        });
      } catch {}
    }
  };

  return (
    <Web3Context.Provider
      value={{
        account,
        setAccount,
        web3,
        setWeb3,
        connectMetamask,
        initializeWeb3,
        contract,
        pillContract,
        keyContract,
        initialized,
      }}
    >
      {children}
    </Web3Context.Provider>
  );
};

export { Web3Context, Web3ContextProvider };
