import React, { useState } from "react";

import BorisMask from "../../assets/images/boris-mask-1.png";
import NoiseBG from "../../components/NoiseBG/NoiseBG";
import Menu from "../../components/Menu/Menu";
import PurchaseCard from "../../components/lib/PurchaseCard/PurchaseCard";
import WalletInfoHeader from "../../components/WalletInfoHeader/WalletInfoHeader";

import './HomePage.css';

function HomePage() {
  const [isOpen, setIsOpen] = useState(false);
  const [pillId, setPillId] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const onOpen = () => {
    setMessage("");
    setLoading(false);
    setPillId("");
    setIsOpen(!isOpen);
  };

  const onPillIdChange = (e) => {
    setPillId(e.target.value);
  };

  const onCheckPill = async () => {
    if (!pillId || pillId.trim() === "") {
      alert("Please input a pill id.");
      return;
    }

    setLoading(true);

    try {
      const data = await fetch(
        `https://api.byopills.com/apostles/genesis/pillused/${pillId}`
      );
      const parsed = await data.json();
      if (parsed.used) {
        setMessage("This pill has been used !");
      } else {
        setMessage("This pill is still eligible for Apostle claiming.");
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  return (
    <main id="body-inner">
      <WalletInfoHeader />
      <NoiseBG />
      <div id="scroll-container">
        <Menu onOpen={onOpen} />
        <div id="content-wrap">
          <div
            id="page-header"
            className="ph-full ph-cap-xxlg ph-center ph-image-cropped ph-image-cover-2 ph-content-parallax"
          >
            <div className="page-header-inner tt-wrap">
              <div className="ph-image">
                <div className="ph-image-inner">
                  <img src={BorisMask} alt="BorisMask" />
                </div>
              </div>
              <div className="ph-caption">
                <h1 className="ph-caption-title ph-appear">
                  CLAIM YOUR APOSTLE
                </h1>
                <div className="ph-caption-title-ghost ph-appear">
                  CLAIM YOUR APOSTLE
                </div>
              </div>

              <div className="ph-claim">
                <PurchaseCard />
              </div>

         
            </div>
          </div>
        </div>

        <div className={isOpen ? "sidebar open" : "sidebar closed"}>
          <div className="ph-caption">
            <h1 className="ph-caption-title ph-appear">CHECK PILL STATUS</h1>
            <div className="ph-caption-title-ghost ph-appear">
              CHECK PILL STATUS
            </div>
          </div>
          <input
            value={pillId}
            onChange={onPillIdChange}
            placeholder="Input Pill ID here ..."
          />
          {loading ? (
            <button>LOADING ...</button>
          ) : (
            <button onClick={onCheckPill}>Check Pill</button>
          )}

          {message !== "" && <div className="ph-appear side-message-big">{message}</div>}
          <i onClick={onOpen} className="fa fa-close sidebarclose" />
        </div>

      
      </div>
    </main>
  );
}

export default HomePage;
