import React, { useEffect, useContext } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";

// Contexts
import { Web3Context } from "../data/Web3Context/Web3Context";

// Pages
import HomePage from "../pages/HomePage/HomePage";
import ModalRoot from "../data/ModalContext/ModalRoot";

import "./App.css";

function App() {
  const { initializeWeb3 } = useContext(Web3Context);

  useEffect(() => {
    initializeWeb3();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Router>
      <ModalRoot />
      <ScrollToTop
        top={200}
        style={{
          backgroundColor: "#FD87BF",
          transition: "1s",
        }}
        smooth
        color="white"
      />
      <Switch>
        <Route exact path="/">
          <HomePage />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
