import React, { useContext, useState, useEffect } from "react";
import { SkyLightStateless } from "react-skylight";
import Loader from "react-loader-spinner";
import DualListBox from "react-dual-listbox";

import { Web3Context } from "../../../data/Web3Context/Web3Context";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "react-dual-listbox/lib/react-dual-listbox.css";

import "./PurchaseCard.css";

// const options = [
//   {
//     label: "UNUSED PILLS",
//     options: [
//       { value: "2424", label: "#2424" },
//       { value: "2524", label: "#2424" },
//       { value: "2624", label: "#2424" },
//       { value: "27424", label: "#2424" },
//     ],
//   },
// ];

function PurchaseCard({ nft, color, border }) {
  const { web3, account, connectMetamask, contract, pillContract ,keyContract } = useContext(Web3Context);
  const [transactionHash, setTransactionHash] = useState(null);
  const [keyCount, setKeyCount] = useState(0);
  const [transactionConfirmed, setTransactionConfirmed] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [canMint, setCanMint] = useState(true);
  const [selected, setSelected] = useState([]);
  const [options, setOptions] = useState([]);
  const [loadingInfo, setLoadingInfo] = useState (false);


  useEffect(() => {
    if (!contract || !pillContract || !keyContract) {
      return;
    }

    if (account) {
      getPills();
      getKeys ();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, contract, pillContract, keyContract]);

  const getKeys = async () => {
    const { balanceOf } = keyContract.methods;
    const keyBalance = await balanceOf (account, 0).call(); 
    setKeyCount (keyBalance);
  }

  const getPills = async () => {
    setLoadingInfo (true);
    const { tokenOfOwnerByIndex, balanceOf } = pillContract.methods;
    const { isPillUsed } = contract.methods;
    var tempOptions = [];
    const pillBalance = await balanceOf(account).call();
    for (let index = 0; index < pillBalance; index++) {
      const pillIndex = await tokenOfOwnerByIndex(account, index).call();
      const pillUsed = await isPillUsed (pillIndex).call();
      if (!pillUsed) {
        tempOptions.push({ value: parseInt(pillIndex), label: `#${pillIndex}` });
      }
    }

    if (tempOptions.length > 0) { 
      tempOptions =  tempOptions.sort ((a,b) => a.value - b.value);
      console.log (tempOptions)
      const optionObj = {
        label: "ELIGIBLE PILLS",
        options: tempOptions,
      };
      const managedOptions = [optionObj]
      setOptions(managedOptions);
    }

    setLoadingInfo (false);

  };

  const sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  const mint = async () => {
    if (!canMint) {
      return;
    }
    
    // Reset
    setCanMint(false);
    setTransactionHash(null);
    setTransactionConfirmed(false);


    const selectedElements = selected;
    if (selectedElements.length >= 50) {
      alert ("50 per tx. max !");
      return;
    }
    
    const { redeem } = contract.methods;

    redeem(selectedElements).estimateGas(
      {
        from: account,
      },
      async (err, gas) => {
        if (err) {
          if (err.message.indexOf("Redeeming is not active") > -1) {
            alert("Redeeming is not active yet !");
          } else if(err.message.indexOf("Not enough keys")) {
            alert("You do not own enough keys for this transaction !")
          } else {
            alert("An unknown error has occured !");
          }
          setCanMint(true);
          return;
        }

        redeem(selectedElements).send(
          {
            from: account,
            gas: gas,
          },
          async function (error, txHash) {
            if (error) {
              setCanMint(true);
              return;
            }

            setTransactionHash(txHash);
            setModalOpen(true);

            let transactionReceipt = null;
            while (transactionReceipt == null) {
              transactionReceipt = await web3.eth.getTransactionReceipt(txHash);
              await sleep(1000);
            }

            setCanMint(true);
            setTransactionConfirmed(true);
            setTransactionHash(transactionReceipt.transactionHash);
          }
        );
      }
    );
  };

  const fail = () => {
    alert("You have no eligible pills to use for claiming.");
  };

  return (
    <div className="PurchaseCard" style={{ borderColor: border }}>
      <SkyLightStateless
        isVisible={modalOpen}
        onCloseClicked={() => {
          setModalOpen(false);
        }}
      >
        <div className="Modal_Body">
          {!transactionConfirmed ? (
            <div className="Modal_Title">
              <Loader type="TailSpin" color="white" height={50} width={50} />
              <p className="Modal_Status">
                Your transaction is pending ... <br />
                <span>
                  Please wait, or visit the transaction link below for updates
                </span>
              </p>
            </div>
          ) : (
            <div className="Modal_Title">
              <p>Transaction Confirmed !</p>
            </div>
          )}

          <p>SUMMARY</p>
          <div className="separator"></div>
          <div className="Modal_Entries">
            <div className="Modal_Entry">
              <p>TRANSACTION HASH</p>
              <p>
                {transactionHash}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`https://etherscan.io/tx/${transactionHash}`}
                >
                 CLICK HERE
                </a>
              </p>
            </div>
            <div className="Modal_Entry">
              <p>ACCOUNT</p>
              <p>{account}</p>
            </div>
          </div>
          <div className="separator"></div>

          <div className="Modal_Actions">
            <button
              onClick={() => {
                setModalOpen(false);
              }}
            >
              Dismiss
            </button>
          </div>
          {transactionConfirmed && (
            <div className="Modal_Confirmation">
              Your transaction has now confirmed ! Your Apostles will show up on {" "}
              <a href="https://opensea.io/collection/apostles-genesis" target="_blank" rel="noreferrer">Opensea.io</a>
            </div>
          )}
        </div>
      </SkyLightStateless>

      {account && options.length > 0 && (
        <>
        <p>To claim your apostle, please choose the pills you would like to claim with and move them to the right box in the order you would like to claim in.<br/>Use the menu button up top to check which pills still haven't been used.</p>
        <DualListBox
          options={options}
          selected={selected}
          onChange={setSelected}
          preserveSelectOrder
          alignActions="top"
        />
        </>
      )}

      {account && <p style={{marginTop: '10px', fontSize: '1rem'}}>Keys Available : {keyCount > 0 ? keyCount : "No keys remaining."}</p>}

      {account ? (
        options.length > 0 ? (
          <button onClick={() => mint()} className="PurchaseCard_ActionButton">
            CLAIM APOSTLES
          </button>
        ) : (
          <button onClick={() => fail()} className="PurchaseCard_ActionButton">
            {loadingInfo ? "LOADING ..." : "NO ELIGIBLE PILLS"}
          </button>
        )
      ) : (
        <button
          onClick={() => connectMetamask()}
          className="PurchaseCard_ActionButton"
        >
          CONNECT WALLET
        </button>
      )}

          <div style={{marginTop: '20px'}}>
                <a className="simple-text" style={{ zIndex: 9999999 }} rel="noreferrer" href="https://etherscan.io/address/0xe70da20a2b10d60ca620a4e494fe2b37c9499e97" target="_blank">VERIFIED CONTRACT ADDRESS</a>
              </div>
              <div>
                 <a className="simple-text" rel="noreferrer" href="https://opensea.io/collection/apostles-genesis" target="_blank">OPENSEA COLLECTION</a>
              </div>
    </div>
  );
}

export default PurchaseCard;
